import React from 'react';

const RaminPage = () => {
  return (
    <div>
      <h1>Ramin's Art</h1>
      <p>Details about Ramin's art...</p>
    </div>
  );
};

export default RaminPage;
