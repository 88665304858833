import React from 'react';

const DianePage = () => {
  return (
    <div>
      <h1>Diane's Art</h1>
      <p>Details about Diane's art...</p>
    </div>
  );
};

export default DianePage;
