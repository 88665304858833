// MainPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import dianeImage from '../assets/images/diane/diskuswerfer_2.jpg'; // Bild importieren
import leonardoImage from '../assets/images/leonardo/leo_klavier.jpg'; // Bild importieren
import raminImage from '../assets/images/ramin/orion.jpg'; // Bild importieren

const MainPage = () => {
  return (
    <div className="main-page">
      <div className="artists-list">
        <div className="artist">
          <h2>Diane</h2>
          <Link to="/diane">
            <img 
              src={dianeImage} 
              alt="Art by Diane" 
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Link>
        </div>
        <div className="artist">
          <h2>Ramin</h2>
          <Link to="/ramin">
            <img 
              src={raminImage} 
              alt="Art by Ramin" 
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Link>
        </div>
        <div className="artist">
          <h2>Leonardo</h2>
          <Link to="/leonardo">
            <img 
              src={leonardoImage} 
              alt="Art by Leonardo" 
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
