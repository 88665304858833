import React from 'react';

const LeonardoPage = () => {
  return (
    <div>
      <h1>Leonardo's Art</h1>
      <p>Details about Leonardo's art...</p>
    </div>
  );
};

export default LeonardoPage;
