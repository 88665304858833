import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainPage from './components/MainPage';
import DianePage from './components/DianePage';
import RaminPage from './components/RaminPage';
import LeonardoPage from './components/LeonardoPage';
import logo from './assets/images/logo.jpg'; // Logo importieren

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} alt="Logo" className="App-logo" />
          <h1 className="App-title">Welcome to the Arts of Diane, Ramin, and Leonardo</h1>
        </header>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/diane" element={<DianePage />} />
          <Route path="/ramin" element={<RaminPage />} />
          <Route path="/leonardo" element={<LeonardoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
